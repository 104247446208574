import React, { forwardRef } from "react";
import styles from "./Services.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { CardButton } from "../../ui";
import THEME from "../../../state/theme";

export default forwardRef(({ data = [] }, ref) => {
  const servicesList = data
    .map((item, index) => (
      <article key={index}>
        <CardButton
          to={"/services/" + item.id}
          animation={!item.featured && index !== 3 ? "slide-left" : null}
          btn_after="&#xf105;"
          btn_align="right"
          btn_type={item.featured ? "solid-white-tb" : "solid-color-tb"}
          btn_hoverType="solid-white-tb"
          padding
          hide_button={true}
          background={
            index === 3
              ? "url(" + item.img + ") center/cover"
              : item.featured
              ? THEME.color
              : null
          }
          dark={index === 3 ? true : false}
        >
          <div
            className={styles.services}
            style={{ color: index === 3 ? "#fff" : "#000" }}
          >
            <h3>{item.title}</h3>
            <p>{item.text}</p>
          </div>
        </CardButton>
      </article>
    ))
    .slice(0, 9);
  return (
    <div ref={ref}>
      <Layout
        style={{
          background: "rgb(40,40,50)",
        }}
        col="3"
        padding
      >
        <div className="layout-header">
          <span className="subtitle" style={{ color: THEME.color }}>
          </span>
          <h2 style={{ color: THEME.color }}>
          Our services
          </h2>
          <p style={{ color: "#fff" }}>
            We have all the resources needed to build exactly the proyect you want
            from start to finish with our hassle-free turnkey contracts.
          </p>
        </div>
        {servicesList}
        <div className={["layout-footer", styles.footer].join(" ")}>
          <span>
            <b>Make it personal.</b>
          </span>
          <span>Call us and get a special conditions.</span>
        </div>
      </Layout>
    </div>
  );
});
