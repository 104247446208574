import i18n from 'i18next';
import {initReactI18next} from "react-i18next";


import {translations} from './translations';
const config = {
    resources: translations,
    lng: "en",
    keySeparator: false,
    interpolation:{
        escapeValue: false
    }
};
i18n
    .use(initReactI18next)
    .init(config);

export default i18n;