import React, { forwardRef } from "react";
import styles from "./Section404.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";
import { Button, Logo } from "../../elements";
import { useCustomState } from "../../../state/state";
import { Link } from "react-router-dom";

export default ({}) => {
  return (
    <div className={styles.container}>
      <Link to="/">
        <Logo dark></Logo>
      </Link>
      <div className={styles.not_found_card}>
        <h1 className={styles.not_found_title}>404</h1>
        <h2 className={styles.not_found_subtitle}>Page not found.</h2>
                    <Button to={"/"} hoverType="solid-white-tb">
              Go to site
            </Button>
      </div>
    </div>
  );
};
