export const HOST = "https://api.desarrollotecno.com";
// export const HOST = "http://localhost:3010"

/**
 * 
 * @param {*} name The name introduced in the form
 * @param {*} email The email introduced
 * @param {*} comments The comments introduced
 * @param {*} phone The phone introduced
 * @param {*} company The company name introduced
 * @param {*} setSending Function to set form state to sending.
 * @param {*} setError Function to set form state to error. Changes color of info message.
 * @param {*} setStatus Function to set form status state. Message displayed to user after submitting form.
 */

export async function sendInformation(email, name, comments, phone, company, setSending, setError, setStatus){

    let method = "POST";
    let headers = {
        'Content-Type': 'application/json'
    };
    let request_body = {
        email: email,
        name: name,
        comments: comments,
        telephone: phone,
        company_name: company
    };

    let fetch_config = {

        method:method,
        headers: headers,
        body: JSON.stringify(request_body)

    };
 
    let url = `${HOST}/send_email`;

    let response = await fetch(url, fetch_config);

    let json = await response.json();    

    if(json.data == true){
        setSending(false);
        setError(false);
        setStatus("Message sent.");
    }else{
        setError(true);
        setStatus("Sorry, message couldn't be send.");
        setSending(false);
    }

    return 1;

}
