import React from "react";
import styles from "./ClientBox.module.scss";

export default ({ data = [] }) => {
  const clients = data
  return (
    <div className={styles.client_box_container}>
      <span className="subtitle" style={{color: "rgb(255, 197, 38)"}}>SOME OF OUR CLIENTS</span>
      <h2></h2>
      <div className={styles.client_image_wrapper}>
        {
          clients.map((client, index) => (
            <img
              src={client}
              className={styles.client_image}
            />
          ))
        }
      </div>
    </div>
  );
};
