import React, { forwardRef } from "react";
import styles from "./TextBlock.module.scss";
import Layout from "../../layouts/Layout/Layout";
import THEME from "../../../state/theme";
import { Button } from "../../elements";
import { useCustomState } from "../../../state/state";

export default forwardRef(({}, ref) => {
  const [state, actions] = useCustomState();
  return (
    <div ref={ref}>
      <Layout col="2" padding>
        <article className={styles.wrapper}>
          <div className={styles.info}>
            <span className={styles.subtitle} style={{ color: THEME.color }}>
              ABOUT US
            </span>
            <h2>We create opportunity for new markets &amp; industries</h2>
            <p className={styles.paragraph}>
              We have over thirty years of experience in the development of engineered industrial systems.
            </p>
            <p className={styles.paragraph}>
              Delivering all of our projects in a timely manner and always surpassing our clients 
              standards by working with rigorous processes to assure top quality and problem free 
              deliveries has helped us get on a path of steady growth since the late 80´s.
            </p>
            {/* <div className={styles.btn_box}>
              <Button to="/blog/post_4" hoverType="solid-gray-tb">
              Read more
              </Button>
              <Button
              click={() => actions.toogleVideo()}
              type="solid-gray-tb"
              hoverType="solid-color-tb"
              after="&#xf105;"
              />
            </div> */}
          </div>
        </article>
        <article className={styles.wrapper}>
          <figure className={styles.image}>
            <div
              className={styles.rectangle}
              style={{ borderColor: THEME.color }}
              />
            <div className={styles.photo}>
              {/* <i
                onClick={() => actions.toogleVideo()}
                className="las la-caret-right"
                style={{ borderColor: THEME.color, color: THEME.color }}
              /> */}
              <span
                style={{
                  background:
                  "url(" +
                  state.data.header_bgs.about_video_poster +
                  ") center/cover",
                }}
                />
            </div>
          </figure>
        </article>
      </Layout>
    </div>
  );
});
