import React, { Fragment, useRef } from "react";
import {
  Features,
  Features2,
  Subscribe,
  Services,
  Team,
  Services3,
  Contacts,
  Slider,
  Reviews,
  Facts,
  TextBlock,
  Portfolio,
  Partners,
  Section404
} from "../../widgets";
// import { Header } from "./components/layouts";
import { useCustomState } from "../../../state/state";
import { Footer, Header, Sidebar } from "../../layouts";
import { ClientBox } from "../../elements";

export default () => {
  const state = useCustomState()[0];

  const home = useRef();
  const about = useRef();
  const services = useRef();
  const projects = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();

    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "services":
        services.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "projects":
        projects.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        
    }
  };

  return (
    <Fragment>
          {/* <Sidebar data={state.data.menu} scrollToSection={scrollToSection}/> */}
          {/* <Header data={state.data.menu} scrollToSection={scrollToSection} /> */}
          {/* <Slider data={state.data.posts} ref={home}/>
          <Services3 data={state.data.services} />
          <TextBlock ref={about}/>
          <Services data={state.data.services} ref={services}/>
          <Portfolio data={state.data.portfolio} ref={projects}/>
          <ClientBox data={state.data.partners} />    
          <Contacts ref={contact}/> */}
          <Section404/>
    </Fragment>
  );
};
