import {es, en} from './languages';
export const translations = {

    en:{
        translation:{...en}
    },
    es:{
        translation:{...es}
    }

}