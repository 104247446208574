import React, { forwardRef } from "react";
import styles from "./Contacts.module.scss";
import Layout from "../../layouts/Layout/Layout";
import { ContactForm } from "../../ui";
import { Button } from "../../elements";
import { useCustomState } from "../../../state/state";

export default forwardRef(({}, ref) => {
  const state = useCustomState()[0];

  return (
    <div ref={ref}>
      <Layout
        style={{
          background:
            "url(" + state.data.header_bgs.contacts_w + ") center/cover",
        }}
        mode="dark"
        col="2"
        padding
      >
        <ContactForm />
        <div className={styles.wrapper}>
          <div className={styles.contact_info}>
            <h2>Inquiries</h2>
            <p>
            For any inquiries, questions or quotes, please call: 
            +52 81-1977-1815 or fill out the following form and our 
            team will be in touch with you.
            </p>
            <h2>Head Office</h2>
            <p>Monterrey, México.</p>
            <p>contact@desarollotecno.com<br></br>
               sales@desarrollotecno.com<br></br>
               Tel: +52 81 1977 1815
            </p>
            {/* <Button
              to="/blog/post_3"
              type="solid-color-tb"
              hoverType="solid-white-tb"
              after="&#xf105;"
            /> */}
          </div>
        </div>
      </Layout>
    </div>
  );
});
