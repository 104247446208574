import React from "react";
import styles from "./Logo.module.scss";
import THEME from "../../../state/theme";

// ORIGINAL
// export default ({ dark }) => (
//   <figure
//     className={[styles.logo, dark ? styles.dark : styles.light].join(" ")}
//     style={{ borderColor: THEME.color }}
//   >
//     <i className="las la-industry" />
//     <span>
//       Build<b>Stars.</b>
//     </span>
//   </figure>
// );

export default ({ dark }) => (
  <figure
    className={[styles.logo, dark ? styles.dark : styles.light].join(" ")}
    style={{ borderColor: THEME.color }}
  >
    <div
      style={{
        background: `url(${require("../../../assets/production_images/logo.webp")}) center/cover`,
        borderColor: THEME.color,
        height: "40px",
        width: "40px",
        marginRight: "8px"
      }}
    ></div>
    <span>
      DESARROLLO
      <br/>
      <span style={{marginLeft: 8}}>TECNOLÓGICO</span>
      {/* </br> */}
    </span>
  </figure>
);
