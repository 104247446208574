import React, { useEffect, Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import {
  Home,  
  NotFound
} from "./components/pages";
import { ModalForm, ModalVideo, RequestForm } from "./components/ui";
import { Spinner } from "./components/elements";
import { useCustomState } from "./state/state";

export default () => {
  const [state, actions] = useCustomState();

  useEffect(() => {
    actions.fetch();
  }, [actions]);

  let app = state.error ? <p>Can't load page</p> : <Spinner fluid />;

  if (state.data) {
    app = (
        <Fragment>          
          <ModalForm />
          <ModalVideo />
          <RequestForm />          
          <Switch>
            <Route path="/" exact component={Home} />            
            <Route component={NotFound} />
          </Switch>          
        </Fragment>
    );
  }

  return <Fragment>{app}</Fragment>;
};
